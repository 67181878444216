import firebase from 'firebase/compat';
import "firebase/auth";


/**
 *  
const config = {
   apiKey: "AIzaSyCgCIPPm7cpVNyKyn1OeXPbAXZa2pH-cO4",
   authDomain: "yuba-5a1ac.firebaseapp.com",
   databaseURL: "https://yuba-5a1ac.firebaseio.com",
   projectId: "yuba-5a1ac",
   storageBucket: "yuba-5a1ac.appspot.com",
   messagingSenderId: "177167562230",
   appId: "1:177167562230:web:de25f0a2691d84774143d7"
};

*/

const config = {
   apiKey: "AIzaSyAdkRq9XQxW9-nZl3NExja5kxIdD9HcnTE",
   authDomain: "yuju-80477.firebaseapp.com",
   databaseURL: "https://yuju-80477.firebaseio.com",
   projectId: "yuju-80477",
   storageBucket: "yuju-80477.appspot.com",
   messagingSenderId: "890565208032",
   appId: "1:890565208032:web:d839b8052c3e561d5e2d85"
 };

firebase.initializeApp(config);


export default firebase;
