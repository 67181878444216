import React from "react";
import { Box, Grid, IconButton, Tooltip } from "@material-ui/core";
import "devextreme/dist/css/dx.light.css";
import Swal from "sweetalert2";
import firebase from "firebase/compat";
import axios from "axios";
import Note from "@material-ui/icons/Note";

import DataGrid, {
  Column,
  FilterRow,
  HeaderFilter,
  Pager,
  Paging,
  Export,
  Lookup,
  Editing,
  Item,
  Popup,
  Form,
  Selection,
  SearchPanel,
} from "devextreme-react/data-grid";
import Card from "components/Card/Card";
import { SelectBox } from "devextreme-react";
import DialogTransaccionesJson from "./DialogTransaccionesJson";

const allowedPageSizes = [5, 10, "Todos"];

const texts = {
  exportAll: "Exportar todos los datos",
  exportSelectedRows: "Exportar filas seleccionadas",
  exportTo: "Exportar",
};

const texts2 = {
  confirmDeleteMessage: "Estas seguro de eliminar la empresa?",
  cancelRowChanges: "Cancelar",
  saveRowChanges: "Guardar",
};

class TransaccionesRealizadas extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modalIsOpenReporte: false,
      rowData: [],
      data: [],
      modalTitle:"",
      listaEmpresas: [],
      usuarioEmpresa: "",
      usuarioRol: "",
    };

    const db = firebase.firestore();
    db.collection("APIEmpresas").onSnapshot(
      (snapshot) => {
        const _data = snapshot.docs.map((doc) => doc.data());
        let usuario = JSON.parse(localStorage.getItem("usuario"));
        this.setState({
          listaEmpresas: _data,
          usuarioEmpresa: usuario.empresa,
          usuarioRol: usuario.rol,
        });

        if (this.state.usuarioEmpresa === null) {
          axios
            .get(
              "https://log.yubapps.com/api/YUBASyncAPI?nombre_coleccion=yubasync_transacciones&yuba_token=ALL&yuba_key=ALL"
            )
            .then((res) => {
              this.setState({ data: res.data });
            });
        } else {
          let empresa = this.state.listaEmpresas.filter(
            (empresa) => empresa.nombre === this.state.usuarioEmpresa
          );
          let key = empresa[0].token;
          let token = empresa[0].access_key;
          axios
            .get(
              `https://log.yubapps.com/api/YUBASyncAPI?nombre_coleccion=yubasync_transacciones&yuba_token=${token}&yuba_key=${key}`
            )
            .then((res) => {
              this.setState({ data: res.data });
            });
        }
      },
      (error) => {
        console.log(error);
      }
    );
    this.closeModalReporte = this.closeModalReporte.bind(this);
  }

  closeModalReporte() {
    this.setState({ modalIsOpenReporte: false });
  }

  render() {
    const renderGridCell = (row) => (
      <Tooltip title="jsonBONE">
        <IconButton>
          <Note
            onClick={() => this.setState({ modalIsOpenReporte: true, rowData: row.data.jsonBONE, modalTitle: row.data.nombre_empresa })}
            style={{
              cursor: "pointer",
              color: "#337ab7",
            }}
          />
        </IconButton>
      </Tooltip>
    );

    const renderGridCell1 = (row) => (
      <Tooltip title="jsonYUJU">
        <IconButton>
          <Note
            onClick={() => this.setState({ modalIsOpenReporte: true, rowData: row.data.jsonYUJU, modalTitle: row.data.nombre_empresa })}
            style={{
              cursor: "pointer",
              color: "#337ab7",
            }}
          />
        </IconButton>
      </Tooltip>
    );

    return (
      <div>
        <DialogTransaccionesJson
          modalIsOpenReporte={this.state.modalIsOpenReporte}
          closeModalReporte={this.closeModalReporte}
          rowData={this.state.rowData}
          title={this.state.modalTitle}
        />
        <Grid>
          <Card
            style={{
              padding: 10,
              width: "100%",
            }}
          >
            <Box width="100%">
              <h4
                style={{
                  fontWeight: 500,
                  position: "relative",
                }}
              >
                TRANSACCIONES REALIZADAS
              </h4>
              <div
                style={{
                  position: "absolute",
                  zIndex: 100,
                  right: 70,
                }}
              ></div>
              <DataGrid
                dataSource={this.state.data}
                keyExpr=""
                showColumnLines={true}
                onRowClick={() => {}}
                onInitNewRow={() => {}}
                onExporting={(e) => {}}
                showHeaderFilter={true}
                showRowLines={true}
                columnAutoWidth={true}
                showBorders={true}
                onCellPrepared={(e) => {
                  e.cellElement.classList.add("adaptiveRowStyle");
                }}
                onRowRemoved={() => {}}
                onRowInserted={() => {}}
                onInitialized={() => {}}
                onRowUpdated={() => {}}
                rowAlternationEnabled={true}
              >
                <Editing
                  mode="popup"
                  allowUpdating={false}
                  allowAdding={true}
                  allowDeleting={false}
                  useIcons={true}
                  texts={texts2}
                >
                  <Popup
                    title="Transacciones Realizadas"
                    showTitle={true}
                    width={700}
                    height={400}
                  />
                  <Form>
                    <Item itemType="group" colCount={2} colSpan={2}>
                    <Item dataField="pk" caption="PK" />
                      <Item dataField="nombre_empresa" caption="Empresa" />
                      <Item dataField="nombre_proveedor" caption="Marketplace" />
                      <Item dataField="fecha_date" caption="Fecha" />
                    </Item>
                  </Form>
                </Editing>
                <HeaderFilter visible={true} />
                <Column dataField="pk" caption="PK" />
                <Column dataField="nombre_empresa" caption="Empresa" />
                <Column dataField="nombre_proveedor" caption="Marketplace" />
                <Column dataField="fecha_date" caption="Fecha" />
                <Column caption="jsonBONE" cellRender={renderGridCell} />
                <Column caption="jsonYUJU" cellRender={renderGridCell1} />
                <Paging defaultPageSize={10} />
                <Pager
                  visible={true}
                  allowedPageSizes={allowedPageSizes}
                  displayMode="full"
                  showPageSizeSelector={true}
                  showInfo={true}
                  showNavigationButtons={true}
                />
                <Export
                  enabled={true}
                  allowExportSelectedData={true}
                  texts={texts}
                />
              </DataGrid>
            </Box>
          </Card>
        </Grid>
      </div>
    );
  }
}

export default TransaccionesRealizadas;
