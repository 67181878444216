import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import firebase from "firebase/compat";
import MaterialTable from "material-table";
import DialogProveedor from "views/Proveedor/DialogProveedor.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import AddAlert from "@material-ui/icons/AddAlert";
import Loop from "@material-ui/icons/Loop";
import Delete from "@material-ui/icons/Delete";
import CloudUpload from "@material-ui/icons/CloudUpload";
import Edit from "@material-ui/icons/Edit";

import { Box, Grid, IconButton, Tooltip } from "@material-ui/core";
import "devextreme/dist/css/dx.light.css";

import DataGrid, {
  Column,
  FilterRow,
  HeaderFilter,
  Pager,
  Paging,
  Export,
  Lookup,
  Editing,
  Item,
  Popup,
  Form,
  Selection,
  SearchPanel,
  Button,
} from "devextreme-react/data-grid";
import Card from "components/Card/Card";

const allowedPageSizes = [5, 10, "Todos"];

const texts = {
  exportAll: "Exportar todos los datos",
  exportSelectedRows: "Exportar filas seleccionadas",
  exportTo: "Export",
};

const texts2 = {
  confirmDeleteMessage: "Estas seguro de eliminar el proveedor?",
  cancelRowChanges: "Cancelar",
  saveRowChanges: "Guardar",
};

let dataGrid;

class Proveedor extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      modalIsOpen: false,
      isEdited: false,
      rowData: {},
      isDeleted: false,
      listadoEmpresas: [],
    };

    const db = firebase.firestore();
    db.collection("APIProveedores").onSnapshot(
      (snapshot) => {
        const _data = snapshot.docs.map((doc) => doc.data());
        this.setState({
          data: _data,
        });
      },
      (error) => {
        console.log(error);
      }
    );

    db.collection("APIEmpresas").onSnapshot(
      (snapshot) => {
        const _data1 = snapshot.docs.map((doc) => doc.data());
        this.setState({ listadoEmpresas: _data1 });
      },
      (error) => {
        console.log(error);
      }
    );

    this.openModal = this.openModal.bind(this);
    this.afterOpenModal = this.afterOpenModal.bind(this);
    this.closeModal = this.closeModal.bind(this);

    const storedUsuario = localStorage.getItem("usuario");
    if (storedUsuario === null) {
      window.location.href = "/admin/dashboard";
    }

    this.onInitialized = this.onInitialized.bind(this);
  }

  openModal() {
    this.setState({ modalIsOpen: true });
  }

  afterOpenModal() {
    // references are now sync'd and can be accessed.
    this.subtitle.style.color = "#f00";
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  guardarData = (row) => {
    console.log(row.data);
    const db = firebase.firestore();
    db.collection("APIProveedores")
      .doc(row.data.codigo)
      .set(row.data)
      .then(function(result) {
        console.log("result", result)
      })
      .catch(function(error) {});
  };

  borrarData = (row) => {
    try {
      const db = firebase.firestore();
      db.collection("APIProveedores")
        .doc(row.data.codigo)
        .delete()
        .then(function(result) {})
        .catch(function(error) {});
    } catch (err) {}
  };

  onInitialized(e) {
    dataGrid = e.component;
  }

  render() {
    const customStyles = {
      content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
      },
    };

    const renderGridCell = (row) => (
      <div>
        <Tooltip title="Integrar Proveedor">
          <IconButton>
            <Loop
              onClick={() => {
                return this.props.history.push({
                  pathname: "/admin/integrarproveedor",
                  state: {
                    codigo_proveedor: row.data.codigo,
                    nombre_proveedor: row.data.nombre,
                  },
                });
              }}
              style={{
                cursor: "pointer",
                color: "#337ab7",
              }}
            />
          </IconButton>
        </Tooltip>
        <Tooltip title="Eliminar">
          <IconButton>
            <Delete
              onClick={() => {
                dataGrid.deleteRow(row.rowIndex);
              }}
              style={{
                cursor: "pointer",
                color: "#337ab7",
              }}
            />
          </IconButton>
        </Tooltip>{" "}
        <Tooltip title="Editar">
          <IconButton>
            <Edit
              onClick={() => {
                dataGrid.editRow(row.rowIndex);
              }}
              style={{
                cursor: "pointer",
                color: "#337ab7",
              }}
            />
          </IconButton>
        </Tooltip>
      </div>
    );

    console.log("data", this.state.data);

    return (
      <div>
        <Grid>
          <Card
            style={{
              padding: 10,
              width: "100%",
            }}
          >
            <Box width="100%">
              <h4
                style={{
                  fontWeight: 500,
                }}
              >
                LISTADO DE MARKETPLACES
              </h4>
              <DataGrid
                dataSource={this.state.data}
                keyExpr=""
                showColumnLines={true}
                // onRowClick={e => console.log(e)}
                onExporting={(e) => {}}
                showHeaderFilter={true}
                showRowLines={true}
                columnAutoWidth={true}
                showBorders={true}
                onCellPrepared={(e) => {
                  e.cellElement.classList.add("adaptiveRowStyle");
                }}
                onRowRemoved={(row) => {
                  this.borrarData(row);
                }}
                onRowInserted={(row) => {
                  this.guardarData(row);
                }}
                onInitialized={this.onInitialized}
                onRowUpdated={(row) => {
                  this.guardarData(row);
                }}
                rowAlternationEnabled={true}
              >
                <Editing
                  mode="popup"
                  allowUpdating={false}
                  allowAdding={false}
                  allowDeleting={false}
                  useIcons={true}
                  texts={texts2}
                >
                  <Popup
                    title="LISTADO DE PROVEEDORES"
                    showTitle={true}
                    width={700}
                    height={280}
                  />
                  <Form>
                    <Item itemType="group" colCount={2} colSpan={2}>
                      <Item dataField="codigo" caption="Código" />
                      <Item dataField="nombre" caption="Nombre" />
                    </Item>
                  </Form>
                </Editing>
                <HeaderFilter visible={true} />
                <Column
                  caption="Acciones"
                  cellRender={renderGridCell}
                  width={160}
                />
                <Column dataField="codigo" caption="Código" />
                <Column dataField="nombre" caption="Nombre" />
                <Paging defaultPageSize={10} />
                <Pager
                  visible={true}
                  allowedPageSizes={allowedPageSizes}
                  displayMode="full"
                  showPageSizeSelector={true}
                  showInfo={true}
                  showNavigationButtons={true}
                />
                <Export
                  enabled={true}
                  allowExportSelectedData={true}
                  texts={texts}
                />
              </DataGrid>
            </Box>
          </Card>
        </Grid>
      </div>
    );
  }
}

export default Proveedor;
