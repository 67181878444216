import React from "react";
import firebase from "firebase/compat";
import MaterialTable from "material-table";
import uuid from "uuid-random";
import jwt from "jwt-simple";
import Http from "@material-ui/icons/Http";
import Delete from "@material-ui/icons/Delete";
import AssignmentRounded from "@material-ui/icons/AssignmentRounded";

import { Box, Grid, IconButton, Tooltip } from "@material-ui/core";
import "devextreme/dist/css/dx.light.css";
import "./IntegrarProveedor.css";
import Swal from "sweetalert2";

import DataGrid, {
  Column,
  FilterRow,
  HeaderFilter,
  Pager,
  Paging,
  Export,
  Lookup,
  Editing,
  Item,
  Popup,
  Form,
  Selection,
  SearchPanel,
} from "devextreme-react/data-grid";
import Card from "components/Card/Card";

const allowedPageSizes = [5, 10, "Todos"];

const texts = {
  exportAll: "Exportar todos los datos",
  exportSelectedRows: "Exportar filas seleccionadas",
  exportTo: "Export",
};

const texts2 = {
  confirmDeleteMessage: "Estas seguro de eliminar el proveedor?",
  cancelRowChanges: "Cancelar",
  saveRowChanges: "Guardar",
};

let dataGrid;

class IntegrarProveedor extends React.Component {
  constructor(props) {
    super(props);

    try {
      this.state = {
        data: [],
        modalIsOpen: false,
        isEdited: false,
        rowData: {},
        isDeleted: false,
        codigo_proveedor: props.location.state.codigo_proveedor,
        nombre_proveedor: props.location.state.nombre_proveedor,
        listadoEmpresas: [],
      };

      console.log("CODIGO_PROVEEDOR: " + props.location.state.codigo_proveedor);

      const db = firebase.firestore();

      db.collection("APIEmpresas").onSnapshot(
        (snapshot) => {
          const _data = snapshot.docs.map((doc) => doc.data());
          let usuario = JSON.parse(localStorage.getItem("usuario"));
          let evaluar = _data.filter(
            (e) => e.codigo === usuario.codigo_empresa
          );

          if (evaluar.length === 0) {
            this.setState({ listadoEmpresas: _data });
          } else {
            this.setState({ listadoEmpresas: evaluar });
          }
        },
        (error) => {
          console.log(error);
        }
      );

      let citiesRef = db
        .collection("APIIntegracionProveedores")
        .where("codigo_proveedor", "==", props.location.state.codigo_proveedor);
      let query = citiesRef.onSnapshot(
        (snapshot) => {
          const _data = snapshot.docs.map((doc) => doc.data());

          let usuario = JSON.parse(localStorage.getItem("usuario"));
          let evaluar = this.state.listadoEmpresas.filter(
            (e) => e.nombre === usuario.empresa
          );
          if (evaluar.length === 0) {
            this.setState({
              data: _data,
            });
          } else {
            let datos = _data.filter(
              (e) => e.nombre_empresa === evaluar[0].nombre
            );
            this.setState({ data: datos });
          }
        },
        (error) => {
          console.log(error);
        }
      );

      this.openModal = this.openModal.bind(this);
      this.afterOpenModal = this.afterOpenModal.bind(this);
      this.closeModal = this.closeModal.bind(this);

      const storedUsuario = localStorage.getItem("usuario");
      if (storedUsuario === null) {
        window.location.href = "/admin/dashboard";
      }
    } catch (err) {
      window.location.href = "/admin/dashboard";
    }

    this.onInitialized = this.onInitialized.bind(this);

  }

  openModal() {
    this.setState({ modalIsOpen: true });
  }

  afterOpenModal() {
    // references are now sync'd and can be accessed.
    this.subtitle.style.color = "#f00";
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  onInitialized(e) {
    dataGrid = e.component;
  }

  render() {
    const customStyles = {
      content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
      },
    };

    const tituloIntegraciones =
      "Integraciones de Marketplaces : " + this.state.nombre_proveedor;

    let proveedores = this.state.listadoEmpresas.map((e) => e.nombre);

    let positionEditorOptions = {
      items: proveedores,
      searchEnabled: true,
      value: "",
    };

    let ancho = window.screen.width;

    const renderGridCell = (row) => {
      return (
        <div>
          <Tooltip title="Asignar">
            <IconButton>
              <Http
                onClick={() => {
                  return this.props.history.push({
                    pathname: "/admin/integrarproveedordetalle",
                    state: {
                      codigo_proveedor: this.state.codigo_proveedor,
                      nombre_proveedor: this.state.nombre_proveedor,
                      codigo_empresa: row.data.codigo_empresa,
                      nombre_empresa: row.data.nombre_empresa,
                    },
                  });
                }}
                style={{
                  cursor: "pointer",
                  color: "#337ab7",
                }}
              />
            </IconButton>
          </Tooltip>{" "}
          <Tooltip title="Eliminar">
            <IconButton>
              <Delete
                onClick={() => {
                  dataGrid.deleteRow(row.rowIndex);
                }}
                style={{
                  cursor: "pointer",
                  color: "#337ab7",
                }}
              />
            </IconButton>
          </Tooltip>
        </div>
      );
    };

    console.log("data", this.state.data)

    return (
      <div>
        <Grid>
          <Card
            style={{
              padding: 10,
              width: "100%",
            }}
          >
            <Box width="100%">
              <h4
                style={{
                  fontWeight: 500,
                }}
              >
                {tituloIntegraciones.toUpperCase()}
              </h4>
              <DataGrid
                dataSource={this.state.data}
                keyExpr="codigo_proveedor"
                showColumnLines={true}
                // onRowClick={e => console.log(e)}
                onCellPrepared={(e) => {
                  e.cellElement.classList.add("adaptiveRowStyle");
                }}
                onExporting={(e) => {}}
                showHeaderFilter={true}
                showRowLines={true}
                columnAutoWidth={true}
                showBorders={true}
                onRowRemoved={(row) => {
                  let oldData = row.data;

                  new Promise((resolve) => {
                    setTimeout(() => {
                      resolve();
                      const data = [...this.state.data];
                      data.splice(data.indexOf(oldData), 1);

                      let _codigo_empresa = oldData.codigo_empresa;
                      let _nombre_empresa = "";

                      this.state.listadoEmpresas.forEach(function(element) {
                        if (element.codigo == _codigo_empresa) {
                          _nombre_empresa = element.nombre;
                        }
                      });

                      const db = firebase.firestore();
                      db.collection("APIIntegracionProveedores")
                        .doc(
                          _codigo_empresa + "_" + this.state.codigo_proveedor
                        )
                        .delete()
                        .then((result) => {
                          Swal.fire({
                            position: "center",
                            icon: "success",
                            title: "Elemento eliminado",
                            showConfirmButton: false,
                            timer: 1500,
                          });
                        })
                        .catch(function(error) {});
                    }, 100);
                  });
                }}
                onRowInserted={(row) => {
                  let newData = this.state.listadoEmpresas.filter(
                    (e) => e.nombre === row.data.nombre_empresa
                  );
                  new Promise((resolve) => {
                    setTimeout(() => {
                      resolve();
                      const data = [...this.state.data];
                      data.push(newData[0].codigo);

                      let _codigo_empresa = newData[0].codigo;
                      let _nombre_empresa = "";

                      this.state.listadoEmpresas.forEach(function(element) {
                        if (element.codigo == _codigo_empresa) {
                          _nombre_empresa = element.nombre;
                        }
                      });
       
                      const db = firebase.firestore();
                      db.collection("APIIntegracionProveedores")
                        .doc(
                          _codigo_empresa + "_" + this.state.codigo_proveedor
                        )
                        .set({
                          codigo_empresa: _codigo_empresa,
                          nombre_empresa: _nombre_empresa,
                          codigo_proveedor: this.state.codigo_proveedor,
                          nombre_proveedor: this.state.nombre_proveedor,
                        })
                        .then((result) => {
                          Swal.fire({
                            position: "center",
                            icon: "success",
                            title: "Elemento creado",
                            showConfirmButton: false,
                            timer: 1500,
                          });
                        })
                        .catch(function(error) {});
                    }, 100);
                  });
                }
              }
                onInitialized={this.onInitialized}
                onRowUpdated={(row) => {}}
                rowAlternationEnabled={true}
              >
                <Editing
                  mode="popup"
                  allowUpdating={false}
                  allowAdding={true}
                  allowDeleting={false}
                  useIcons={true}
                  texts={texts2}
                >
                  <Popup
                    title="LISTADO DE PROVEEDORES"
                    showTitle={true}
                    width={ ancho > 800 ? "700px" : ""}
                    height={ ancho > 800 ? "220px" : ""}
                  />
                  <Form>
                    <Item itemType="group" colCount={2} colSpan={2}>
                      <Item
                        dataField="nombre_empresa"
                        caption="Empresa"
                        editorType="dxSelectBox"
                        editorOptions={positionEditorOptions}
                        label={{ location: ancho < 800 ? "top" : "left" }}
                      />
                    </Item>
                  </Form>
                </Editing>
                <HeaderFilter visible={true} />
                <Column
                  caption="Acciones"
                  cellRender={renderGridCell}
                />
                <Column dataField="nombre_empresa" caption="Empresa" />
                <Paging defaultPageSize={10} />
                <Pager
                  visible={true}
                  allowedPageSizes={allowedPageSizes}
                  displayMode="full"
                  showPageSizeSelector={true}
                  showInfo={true}
                  showNavigationButtons={true}
                />
                <Export
                  enabled={true}
                  allowExportSelectedData={true}
                  texts={texts}
                />
              </DataGrid>
            </Box>
          </Card>
        </Grid>
      </div>
    );
  }
}

export default IntegrarProveedor;
